import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Button, Timeline } from 'rsuite';
//  import {PoProductsDataElements} from '../../dataComponentsXdock/PoProductsDataElements';
//  import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import HawbDataElements from '../../dataComponentsXdock/HawbDataElements';
// import ReactExport from "react-export-excel";
import * as moment from 'moment'
import ReceiveMasterDrawer from '../Shipment/ReceiveMasterDrawer';
import { formatNumberForAPI, formatStringForAPI, formatDateForUrlSearch, formatBitToTrueFalse } from '../../../resources/logic/functions';
import LoadDataElements from '../../dataComponentsXdock/LoadDataElements';
import MasterEvolveDataDrawer from '../Shipment/MasterEvolveDataDrawer';
import AddNewHawbModal from '../Shipment/AddNewHawbModal';
import AirlineRecoveryDrawer from './AirlineRecoveryDrawer';
import XDockReceiveFormDataElements from '../../dataComponentsXdock/XDockReceiveFormDataElements';
import { formatForExcelExport } from '../../../resources/logic/functions';
import { appAPI } from '../../../app/appConfig';
import ReactExport from "react-data-export";
import RUploader from '../../componentsGeneral/Rsuite/Uploader/RUploader';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import XdockMawbFileUploadsDataElements from '../../dataComponentsXdock/XdockMawbFileUploadsDataElements';

const tableIdCol = "MasterID"

//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")



//For Rsuite NavBar
const styles = {
  marginBottom: 50
};


class MasterDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      //Receive Master Drawer
      ReceiveDict: {},
      unsavedReceiveChanges: false,
      showReceiveDrawer: false,
      ReceiveDrawerHawbID: null,
      ReceiveDrawerHouse: null,
      ReceiveDrawerNo: null,
      ReceiveDrawerPMC: null,
      ReceiveDrawerPickupBy: null,
      ReceiveDrawerTrips: null,
      ReceiveDrawerArrivalTime: null,
      ReceiveDrawerLFD: null,
      ReceiveDrawerArrivalAtWarehouse: null,
      ReceiveDrawerDeliveryTime: null,
      ReceiveDrawerNotes: null,

      //Airline Recovery Drawer
      AirlineRecoveryDict: {

        AirlineRecoveryDriverAssigned: null,
        AirlineRecoveryNote: null,



        AirlineRecoveryCartonsRecovered: null,
        AirlineRecoveryPickedUpBy: null,
        AirlineRecoveryCarrier: null,
      },
      AirlineRecoveryPlannedPickupDT: null,
      AirlineRecoveryPickupDate: null,
      AirlineRecoveryLFD: null,
      AirlineRecoveryPMC: null,

      unsavedAirlineRecoveryChanges: false,
      showAirlineRecoveryDrawer: false,
      AirlineRecoveryFreightRecovered: null,
      AirlineRecoveryTripMade: null,

      //Add Evolve Data Values
      showEvolveDataDrawer: false,
      EvolveDataMasterID: null,
      EvolveDataMaster: null,
      EvolveDataNo: null,
      EvolveDataPMC: null,
      EvolveDataPickupBy: null,
      EvolveDataTrips: null,
      EvolveDataRecovered: null,
      EvolveDataArrivalTime: null,
      EvolveDataLFD: null,
      EvolveDataArrivalAtWarehouse: null,
      EvolveDataDeliveryTime: null,
      EvolveDataNotes: null,

      //Add new HAWB modal
      addNewModalShown: false,

      newModalHouseRef: null,
      newModalConsigneeID: null,
      newModalNotes: null,
      newModalCartons: null,
      newModalNewPallets: null,

    };

    //Clear customs
    this.onClickClearCustoms = this.onClickClearCustoms.bind(this);

    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);

    //Receive drawer
    // this.onClickAdd = this.onClickAdd.bind(this);
    this.onChangeReceiveDrawer = this.onChangeReceiveDrawer.bind(this);
    this.onClickReceive = this.onClickReceive.bind(this);
    this.closeReceiveDrawer = this.closeReceiveDrawer.bind(this);
    this.onClickReceiveDrawerSave = this.onClickReceiveDrawerSave.bind(this);
    this.onClickReceiveButton = this.onClickReceiveButton.bind(this);

    //Airline Recovery drawer
    this.onChangeAirlineRecoveryDrawer = this.onChangeAirlineRecoveryDrawer.bind(this);
    // this.onClickAirlineRecovery = this.onClickAirlineRecovery.bind(this);
    this.closeAirlineRecoveryDrawer = this.closeAirlineRecoveryDrawer.bind(this);
    this.onClickAirlineRecoveryDrawerSave = this.onClickAirlineRecoveryDrawerSave.bind(this);
    this.onClickAirlineRecoveryButton = this.onClickAirlineRecoveryButton.bind(this);


    //Evolve Data drawer
    this.onClickOpenEvolveDataDrawer = this.onClickOpenEvolveDataDrawer.bind(this);
    this.closeEvolveDataDrawer = this.closeEvolveDataDrawer.bind(this);
    this.onClickConfirmEvolveData = this.onClickConfirmEvolveData.bind(this);
    this.onChangeEvolveData = this.onChangeEvolveData.bind(this);

    //Add new HAWB modal
    this.handleSaveAddNewModal = this.handleSaveAddNewModal.bind(this);
    this.handleCloseAddNewModal = this.handleCloseAddNewModal.bind(this);
    this.onChangeAddNewModal = this.onChangeAddNewModal.bind(this);

    this.onClickAddMissing = this.onClickAddMissing.bind(this);

    //Customs City
    this.onClickGetCustomsCity = this.onClickGetCustomsCity.bind(this);
    this.onClickGetOnlyCustomsCity = this.onClickGetOnlyCustomsCity.bind(this);

    //File Upload
    this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);
    this.onClickDeleteFileUpload = this.onClickDeleteFileUpload.bind(this);
  }


  // componentDidMount() {
  //   this.preloadReceiveState()
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.HawbData !== this.props.HawbData && this.props.HawbData.length > 0) {
      this.preloadReceiveState()
    }
    if (prevProps.MawbEvolveDataSaveSuccessful !== this.props.MawbEvolveDataSaveSuccessful && this.props.MawbEvolveDataSaveSuccessful) {
      this.closeEvolveDataDrawer()
    }
    if (prevProps.PostHawbSuccessful !== this.props.PostHawbSuccessful && this.props.PostHawbSuccessful) {
      this.onClickReceive(null, this.state.receiveDataRowData, null)
    }

  }


  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });

    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 500);
  }

  //On Click Load Details
  onClickLoadDetails(e, rowData, history) {
    try {
      var paramID = rowData["LoadID"]
      history.push({
        pathname: 'loaddetails',
        search: 'loadid=\'' + paramID + '\''
      });
    }
    catch (e) {
    }
  }

  //On Click Details
  onClickDetails(e, rowData, history) {
    try {
      var paramID = rowData["HouseID"]
      history.push({
        pathname: 'housedetails',
        search: 'houseid=\'' + paramID + '\''
      });
    }
    catch (e) {
    }
  }

  //On click clear customs
  onClickClearCustoms(rowData, keyColumn) {
    if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
      if (rowData.CustomsCleared) {
        window.alert('Customs already cleared.')
      }
      else if (window.confirm('Clear Customs?')) {
        this.props.hawbRecoveryPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,
          null,
          formatNumberForAPI(rowData.HouseID),
          null,
          null,
          null,
          null,
          1,
          this.props.warehouseSelection,
          this.props.customerSelection
        )
      }
    }
    else { window.alert('Please select only one Warehouse and one Customer to save data.') }
  }

  onClickCloseStatus(e) {
    if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
      if (window.confirm('Move to closed status?')) {
        this.props.mawbStatusPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.MawbData[0].MasterID, null, 4, null, this.props.warehouseSelection, this.props.customerSelection)
      }
    }
    else { window.alert('Please select only one Warehouse and one Customer to save data.') }
  }


  //Receive Master drawer
  onClickReceive(e, rowData, history) {
    // this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.MasterID, null, null, null, null, null, null, null, null, null, null, null, null)
    // this.preloadReceiveState()
    this.setState({
      ReceiveDict: {},
      unsavedReceiveChanges: false,
      receiveDataRowData: rowData,
      showReceiveDrawer: true,
      ReceiveDrawerHawbID: rowData.HouseID,

      //Add Evolve Data Values
      ReceiveDrawerHouse: rowData.HouseRef,
      ReceiveDrawerNo: rowData.No,
      ReceiveDrawerPMC: rowData.PMC,
      ReceiveDrawerPickupBy: rowData.PickupBy,
      ReceiveDrawerTrips: rowData.Trips,
      ReceiveDrawerArrivalTime: rowData.ArrivalTime,
      ReceiveDrawerLFD: rowData.LFD,
      ReceiveDrawerArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
      ReceiveDrawerDeliveryTime: rowData.DeliveryTime,
      ReceiveDrawerNotes: rowData.Notes
    });
    this.preloadReceiveState()
  }
  onClickReceiveButton(e) {
    // this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.MasterID, null, null, null, null, null, null, null, null, null, null, null, null)
    // this.preloadReceiveState()
    this.setState({
      ReceiveDict: {},
      unsavedReceiveChanges: false,
      receiveDataRowData: this.props.HawbData[0],
      showReceiveDrawer: true,
      // ReceiveDrawerHawbID: rowData.HouseID,

      //Add Evolve Data Values
      // ReceiveDrawerHouse: rowData.HouseRef,
      // ReceiveDrawerNo: rowData.No,
      // ReceiveDrawerPMC: rowData.PMC,
      // ReceiveDrawerPickupBy: rowData.PickupBy,
      // ReceiveDrawerTrips: rowData.Trips,
      // ReceiveDrawerArrivalTime: rowData.ArrivalTime,
      // ReceiveDrawerLFD: rowData.LFD,
      // ReceiveDrawerArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
      // ReceiveDrawerDeliveryTime: rowData.DeliveryTime,
      // ReceiveDrawerNotes: rowData.Notes
    });
    this.preloadReceiveState()
  }
  preloadReceiveState() {
    var receivedDict = {};
    // var testDict = {}
    for (let k in this.props.HawbData) {
      // receivedDict[this.props.HawbData[k].HouseID] = this.props.HawbData[k].Cartons - this.props.HawbData[k].RecoveredPCS

      // receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': this.props.HawbData[k].Cartons - this.props.HawbData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
      receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': null, 'Pallets': null, 'Notes': '', 'Customs': false }

    }
    this.setState({
      unsavedReceiveChanges: true,
      ReceiveDict: receivedDict
    });
  }
  onChangeReceiveDrawer = (event, item, label) => {
    var currentReceiveDict = this.state.ReceiveDict
    var houseId = item.HouseID;

    currentReceiveDict[houseId][label] = event

    this.setState({
      unsavedReceiveChanges: true,
      ReceiveDict: currentReceiveDict
    });

  };
  onClickReceiveDrawerSave(e) {
    if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

      // if (window.confirm('Save Data?')) {
      var totalRecoverdNum = 0
      for (let k in this.state.ReceiveDict) {

        try { var recoveredNum = this.state.ReceiveDict[k]['Recovered'] }
        catch (e) { var recoveredNum = 0 }
        try { var palletNum = this.state.ReceiveDict[k]['Pallets'] }
        catch (e) { var palletNum = 0 }

        if ((recoveredNum === null || recoveredNum == 0) && (palletNum === null || palletNum == 0)) {
        }
        else {
          try {
            this.props.hawbRecoveryPost(
              this.props.currentUserEmail,
              this.props.userSignInData.AuthToken,
              formatNumberForAPI(this.state.receiveDataRowData.MasterID),
              formatNumberForAPI(k),
              null,
              formatNumberForAPI(this.state.ReceiveDict[k]['Recovered']),
              formatNumberForAPI(this.state.ReceiveDict[k]['Pallets']),
              formatStringForAPI(this.state.ReceiveDict[k]['Notes']),
              this.state.ReceiveDict[k]['Customs'],
              this.props.warehouseSelection,
              this.props.customerSelection
            )
            totalRecoverdNum = Number(totalRecoverdNum) + Number(recoveredNum)
          }
          catch (e) { }
        }
      }


      try {
        var currentMawbData = this.props.MawbData.filter(x => x.MasterID === this.state.receiveDataRowData.MasterID)
        var totalCartons = Number(currentMawbData[0].TotCartons)
        var remainingCartons = Number(currentMawbData[0].TotCartons) - Number(currentMawbData[0].RecoveredPCS) - Number(totalRecoverdNum)
        var totalRecoveredCartons = Number(currentMawbData[0].RecoveredPCS) + Number(totalRecoverdNum)
      }
      catch (e) {
        var remainingCartons = 0
        var totalRecoveredCartons = 0
        var totalCartons = 0
      }

      if (this.props.customerSelection === 1) {
        if (remainingCartons > 0) {
          this.props.hawbRecoveredShortPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, formatNumberForAPI(this.state.receiveDataRowData.MasterID), totalCartons, totalRecoveredCartons, this.props.warehouseSelection, this.props.customerSelection)
        }
      }


      this.setState({
        unsavedReceiveChanges: false,
        showReceiveDrawer: false
      });
      // }
    }
    else { window.alert('Please select only one Warehouse and one Customer to save data.') }
  }
  closeReceiveDrawer() {
    if (this.state.unsavedReceiveChanges) {
      if (window.confirm('Leave without saving changes?')) {
        this.setReceiveDrawerToClose()
      }
    }
    else {
      this.setReceiveDrawerToClose()
    }
  }

  setReceiveDrawerToClose() {
    this.setState({
      ReceiveDict: {},

      unsavedReceiveChanges: false,

      showReceiveDrawer: false,

      ReceiveDrawerHawbID: null,
      ReceiveDrawerHouse: null,
      ReceiveDrawerNo: null,
      ReceiveDrawerPMC: null,
      ReceiveDrawerPickupBy: null,
      ReceiveDrawerTrips: null,
      ReceiveDrawerArrivalTime: null,
      ReceiveDrawerLFD: null,
      ReceiveDrawerArrivalAtWarehouse: null,
      ReceiveDrawerDeliveryTime: null,
      ReceiveDrawerNotes: null,
    });
  }







  //Airline Recovery drawer
  // onClickAirlineRecovery(e, rowData, history) {
  //   // this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, rowData.MasterID, null, null, null, null, null, null, null, null, null, null, null, null)
  //   // this.preloadReceiveState()
  //   this.setState({
  //     AirlineRecoveryDict: {},
  //     unsavedAirlineRecoveryChanges: false,
  //     showAirlineRecoveryDrawer: true,


  //     AirlineRecoveryPlannedPickupDT: null,
  //     AirlineRecoveryDriverAssigned: null,
  //     AirlineRecoveryNote: null,
  //     AirlineRecoveryTripMade: null,
  //     AirlineRecoveryFreightRecovered: null,
  //     AirlineRecoveryPickupDate: null,
  //     AirlineRecoveryCartonsRecovered: null,
  //     AirlineRecoveryPickedUpBy: null,
  //     AirlineRecoveryCarrier: null,


  //     // receiveDataRowData: rowData,


  //     //Add Evolve Data Values
  //     ReceiveDrawerHouse: rowData.HouseRef,
  //     ReceiveDrawerNo: rowData.No,
  //     ReceiveDrawerPMC: rowData.PMC,
  //     ReceiveDrawerPickupBy: rowData.PickupBy,
  //     ReceiveDrawerTrips: rowData.Trips,
  //     ReceiveDrawerArrivalTime: rowData.ArrivalTime,
  //     ReceiveDrawerLFD: rowData.LFD,
  //     ReceiveDrawerArrivalAtWarehouse: rowData.ArrivalAtWarehouse,
  //     ReceiveDrawerDeliveryTime: rowData.DeliveryTime,
  //     ReceiveDrawerNotes: rowData.Notes
  //   });
  //   this.preloadReceiveState()
  // }
  onClickAirlineRecoveryButton(e) {

    var plannedPickupDT = null
    try {
      plannedPickupDT = this.props.MawbData[0].PlannedPickupDT
    }
    catch (e) { }
    var lfd = null
    try {
      lfd = this.props.MawbData[0].LFD
    }
    catch (e) { }
    var pmc = null
    try {
      pmc = this.props.MawbData[0].PMC
    }
    catch (e) { }
    this.setState({
      AirlineRecoveryDict: {

        AirlineRecoveryDriverAssigned: null,
        AirlineRecoveryNote: null,

        AirlineRecoveryCartonsRecovered: null,
        AirlineRecoveryPickedUpBy: null,
        AirlineRecoveryCarrier: null,
      },
      unsavedAirlineRecoveryChanges: false,
      showAirlineRecoveryDrawer: true,
      AirlineRecoveryFreightRecovered: null,
      AirlineRecoveryTripMade: null,
      AirlineRecoveryPlannedPickupDT: plannedPickupDT,
      AirlineRecoveryPickupDate: null,
      AirlineRecoveryLFD: lfd,
      AirlineRecoveryPMC: pmc,

      // AirlineRecoveryDict: {},
      // AirlineRecoveryFreightRecovered: null,
      // unsavedAirlineRecoveryChanges: false,
      // showAirlineRecoveryDrawer: true,

      // receiveDataRowData: this.props.HawbData[0],

    });
    // this.preloadReceiveState()
  }
  // preloadReceiveState() {
  //   var receivedDict = {};
  //   // var testDict = {}
  //   for (let k in this.props.HawbData) {
  //     // receivedDict[this.props.HawbData[k].HouseID] = this.props.HawbData[k].Cartons - this.props.HawbData[k].RecoveredPCS

  //     // receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': this.props.HawbData[k].Cartons - this.props.HawbData[k].RecoveredPCS, 'Pallets': null, 'Notes': '', 'Customs': false }
  //     receivedDict[this.props.HawbData[k].HouseID] = { 'Recovered': null, 'Pallets': null, 'Notes': '', 'Customs': false }

  //   }
  //   this.setState({
  //     unsavedReceiveChanges: true,
  //     ReceiveDict: receivedDict
  //   });
  // }


  onChangeAirlineRecoveryDrawer = (event, label) => {
    var currentReceiveDict = this.state.AirlineRecoveryDict

    currentReceiveDict[label] = event

    this.setState({
      unsavedAirlineRecoveryChanges: true,
      AirlineRecoveryDict: currentReceiveDict
    });
  };
  onChangePlannedPickupDT = (event) => {
    this.setState({
      unsavedAirlineRecoveryChanges: true,
      AirlineRecoveryPlannedPickupDT: event
    });
  }
  onChangeLFD = (event) => {
    this.setState({
      unsavedAirlineRecoveryChanges: true,
      AirlineRecoveryLFD: event
    });
  }
  onChangeAirlineRecoveryPMC = (event) => {
    this.setState({
      unsavedAirlineRecoveryChanges: true,
      AirlineRecoveryPMC: event
    });
  }
  onChangeAirlineTripMade = (event) => {
    // var currentReceiveDict = this.state.AirlineRecoveryDict
    // currentReceiveDict["AirlineRecoveryTripMade"] = event

    this.setState({
      unsavedAirlineRecoveryChanges: true,
      AirlineRecoveryTripMade: event
    });
  }
  onChangeAirlineFreightRecovered = (event) => {
    if (event == 0) {
      var currentReceiveDict = this.state.AirlineRecoveryDict

      currentReceiveDict["AirlineRecoveryCartonsRecovered"] = null
      currentReceiveDict["AirlineRecoveryPickedUpBy"] = null
      currentReceiveDict["AirlineRecoveryCarrier"] = null

      this.setState({
        AirlineRecoveryDict: currentReceiveDict,
        AirlineRecoveryPickupDate: null
      });
    }

    this.setState({
      unsavedAirlineRecoveryChanges: true,
      AirlineRecoveryFreightRecovered: event,
    });

  }
  onChangePickupDT = (event) => {
    this.setState({
      unsavedAirlineRecoveryChanges: true,
      AirlineRecoveryPickupDate: event
    });
  }
  onClickAirlineRecoveryDrawerSave(e) {
    if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

      // if (window.confirm('Save Data?')) {

      try {
        this.props.mawbRecoveryPost(
          this.props.currentUserEmail,
          this.props.userSignInData.AuthToken,

          formatNumberForAPI(this.props.MawbData[0].MasterID),
          formatDateForUrlSearch(this.state.AirlineRecoveryPlannedPickupDT),
          formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryDriverAssigned),
          formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryNote),
          formatNumberForAPI(this.state.AirlineRecoveryTripMade),
          formatNumberForAPI(this.state.AirlineRecoveryFreightRecovered),
          formatDateForUrlSearch(this.state.AirlineRecoveryPickupDate),
          formatNumberForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryCartonsRecovered),
          formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryPickedUpBy),
          formatStringForAPI(this.state.AirlineRecoveryDict.AirlineRecoveryCarrier),

          formatDateForUrlSearch(this.state.AirlineRecoveryLFD),
          formatNumberForAPI(this.state.AirlineRecoveryPMC),

          this.props.warehouseSelection,
          this.props.customerSelection
        )
      }
      catch (e) { }
      // }

      this.setState({
        unsavedAirlineRecoveryChanges: false,
        showAirlineRecoveryDrawer: false
      });
    }
    else { window.alert('Please select only one Warehouse and one Customer to save data.') }
  }
  closeAirlineRecoveryDrawer() {
    if (this.state.unsavedAirlineRecoveryChanges) {
      if (window.confirm('Leave without saving changes?')) {
        this.setAirlineReceiveDrawerToClose()
      }
    }
    else {
      this.setAirlineReceiveDrawerToClose()
    }
  }
  setAirlineReceiveDrawerToClose() {
    this.setState({
      AirlineRecoveryDict: {
        AirlineRecoveryDriverAssigned: null,
        AirlineRecoveryNote: null,


        AirlineRecoveryCartonsRecovered: null,
        AirlineRecoveryPickedUpBy: null,
        AirlineRecoveryCarrier: null,
      },
      unsavedAirlineRecoveryChanges: false,
      showAirlineRecoveryDrawer: false,
      AirlineRecoveryTripMade: null,
      AirlineRecoveryFreightRecovered: null,
      AirlineRecoveryPlannedPickupDT: null,
      AirlineRecoveryPickupDate: null,
      AirlineRecoveryLFD: null,
      AirlineRecoveryPMC: null,

      // //Airline Recovery Drawer
      // AirlineRecoveryDict: {},
      // unsavedAirlineRecoveryChanges: false,
      // showAirlineRecoveryDrawer: false,
      // AirlineRecoveryPlannedPickupDT: null,
      // AirlineRecoveryDriverAssigned: null,
      // AirlineRecoveryNote: null,
      // AirlineRecoveryTripMade: null,
      // AirlineRecoveryFreightRecovered: null,
      // AirlineRecoveryPickupDate: null,
      // AirlineRecoveryCartonsRecovered: null,
      // AirlineRecoveryPickedUpBy: null,
      // AirlineRecoveryCarrier: null,

    });
  }


  //Evolve Data drawer
  onClickOpenEvolveDataDrawer(e) {
    this.setState({
      evolveDataRowData: this.props.MawbData[0],
      showEvolveDataDrawer: true,
      EvolveDataMasterID: this.props.MawbData[0].MasterID,

      // //Add Evolve Data Values
      EvolveDataMasterID: this.props.MawbData[0].MasterID,
      EvolveDataMaster: this.props.MawbData[0].Master,
      EvolveDataNo: this.props.MawbData[0].No,
      EvolveDataPMC: this.props.MawbData[0].PMC,
      EvolveDataPickupBy: this.props.MawbData[0].PickupBy,
      EvolveDataTrips: this.props.MawbData[0].Trips,
      EvolveDataRecovered: this.props.MawbData[0].AirlineRecovered,


      EvolveDataArrivalTime: this.props.MawbData[0].ArrivalTime,
      EvolveDataLFD: this.props.MawbData[0].LFD,
      EvolveDataArrivalAtWarehouse: this.props.MawbData[0].ArrivalAtWarehouse,

      EvolveDataDeliveryTime: this.props.MawbData[0].DeliveryTime,
      EvolveDataNotes: this.props.MawbData[0].Notes
    });
  }
  onChangeEvolveData = (event, field) => {
    var dict = {};
    dict[field] = event;
    this.setState(
      dict
    );
  };
  onClickConfirmEvolveData(e) {
    if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

      // if (window.confirm('Save New Data?')) {


      this.props.mawbEvolveDataEntryPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        formatNumberForAPI(this.state.EvolveDataMasterID),
        formatStringForAPI(this.state.EvolveDataMaster),

        formatNumberForAPI(this.state.EvolveDataNo),
        formatNumberForAPI(this.state.EvolveDataPMC),
        formatStringForAPI(this.state.EvolveDataPickupBy),
        formatNumberForAPI(this.state.EvolveDataTrips),
        formatDateForUrlSearch(this.state.EvolveDataArrivalTime),
        formatDateForUrlSearch(this.state.EvolveDataLFD),
        formatDateForUrlSearch(this.state.EvolveDataArrivalAtWarehouse),
        formatDateForUrlSearch(this.state.EvolveDataDeliveryTime),
        formatStringForAPI(this.state.EvolveDataNotes),
        formatNumberForAPI(this.state.EvolveDataRecovered),
        this.props.warehouseSelection,
        this.props.customerSelection
      )
      // }
    }
    else { window.alert('Please select only one Warehouse and one Customer to save data.') }

  }
  closeEvolveDataDrawer() {
    this.setState({
      showEvolveDataDrawer: false,

      EvolveDataMasterID: null,
      EvolveDataMaster: null,
      EvolveDataNo: null,
      EvolveDataPMC: null,
      EvolveDataPickupBy: null,
      EvolveDataTrips: null,
      EvolveDataRecovered: null,
      EvolveDataArrivalTime: null,
      EvolveDataLFD: null,
      EvolveDataArrivalAtWarehouse: null,
      EvolveDataDeliveryTime: null,
      EvolveDataNotes: null,
    });
  }




  //Add new HAWB modal
  onClickAddMissing() {
    this.handleOpenAddNewModal()
  }
  handleOpenAddNewModal() {
    this.setState({
      addNewModalShown: true,

      newModalHouseRef: null,
      newModalConsigneeID: null,
      newModalNotes: null,
      newModalCartons: null,
      newModalNewPallets: null,
    });
  }
  onChangeAddNewModal = (event, field) => {
    var dict = {};
    dict[field] = event;
    this.setState(
      dict
    );
  };
  handleCloseAddNewModal() {
    this.setState({
      addNewModalShown: false,

      newModalHouseRef: null,
      newModalConsigneeID: null,
      newModalNotes: null,
      newModalCartons: null,
      newModalNewPallets: null,
    });
  }
  handleSaveAddNewModal() {
    if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

      if (window.confirm('Do you wish to add House Ref?')) {
        this.props.hawbPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.MawbData[0].MasterID, formatStringForAPI(this.state.newModalHouseRef), null, this.state.newModalConsigneeID, formatStringForAPI(this.state.newModalNotes), null, null, null, null, null, null, null, null, null, this.state.newModalCartons, this.state.newModalNewPallets, null, null, null, this.props.warehouseSelection, this.props.customerSelection)
      }
      this.handleCloseAddNewModal()
    }
    else { window.alert('Please select only one Warehouse and one Customer to save data.') }
  }



  //Customs city
  onClickGetCustomsCity() {
    this.props.customsCityAPIMessages(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.MawbData[0].Master, null)
  }

  onClickGetOnlyCustomsCity() {
    this.props.customsCityAPIMessages(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, this.props.MawbData[0].Master, 1)
  }


  //BreakdownForm Excel formatting
  formatDataSet(
    bodyPoData
  ) {
    let formattedData = []
    try {
      formattedData = [
        {
          columns: [
            { title: "MAWB", width: { wpx: 220 }, style: { font: { sz: "24", bold: true } } },
            { title: "HAWB", width: { wpx: 220 }, style: { font: { sz: "24", bold: true } } },
            { title: "Total Cases", width: { wpx: 160 }, style: { font: { sz: "24", bold: true } } },
            { title: "Total Pallets", width: { wpx: 160 }, style: { font: { sz: "24", bold: true } } },
          ],
          data:
            bodyPoData.map((record, index) => {
              return [
                { value: formatForExcelExport(record.Master), style: { font: { sz: "24" } } },
                { value: formatForExcelExport(record.HouseRef), style: { font: { sz: "24" } } },
                {},
                {}
              ];
            })
        }
      ];
    }
    catch (e) { }

    return (
      formattedData
    )
  }




  //File Upload
  onClickDownloadFileUpload(e, rowData, history) {
    try {
      this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.API.data.FileDownloadXDockS3)
    }
    catch (e) {
      window.alert("Error Downloading File.")
    }
  }

  onClickDeleteFileUpload(e, rowData, history) {
    if (window.confirm('Do you wish to delete this file?'))
      this.props.xDockMawbFileUploadPost(
        this.props.currentUserEmail,
        this.props.userSignInData.AuthToken,
        this.props.customerSelection,
        formatNumberForAPI(rowData.FileUploadID),
        null,
        null,
        null,
        null,
        1
      )
  }



  render() {
    const { sortColumn, sortType } = this.state;

    if (this.props.isGetCustomsCityAPILoading) {
      return (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
              <Loader
                  type="TailSpin"
                  color="#3396E5"
                  height="50"
                  width="50"
              />
              <p style={{ marginTop: '20px', fontSize: '20px', color: '#3396E5' }}>Please wait...</p>
          </div>
      );
  }


    let fileUploadDiv;
    let pageHeader;
    let detailsDiv;
    let mawbCurrentStatus;
    if (this.props.isMawbLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isXdockMawbFileUploadLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (this.props.isMawbRecoverySummaryLoading) {
      detailsDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.MawbData[0]) !== 'undefined' && this.props.MawbData != null) {

      pageHeader = <div style={{ float: 'left' }}>  {'Airfreight - Master: ' + this.props.MawbData[0].Master} </div>

      mawbCurrentStatus = this.props.MawbData[0].CurrentMawbStatusID

      fileUploadDiv = RUploader(
        this.props.isMobile,                          // isMobile,
        this.props.history,                           // history,
        this.props.currentUserEmail,                  //currentUserEmail,
        this.props.userSignInData,                    //userSignInData
        this.props.customerSelection,                 //customerSelection
        this.props.XdockMawbFileUploadData,      //fileList,
        this.props.xDockMawbFileUploadPost,     //onUploadAction,
        null,                                         // loading,
        this.props.MawbData[0].MasterID,     // s3FilePrefix,
        appAPI.API.data.FileDownloadXDockS3   //s3 bucket
      )

      detailsDiv =
        <div>

          <Row >
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}>
                {mawbCurrentStatus != 4 ? <Button style={{ float: 'right' }} onClick={(e) => { this.onClickCloseStatus(e) }} >Close Master Status</Button> : ''}
                Status: <strong>{this.props.MawbData[0].MawbStatusName}</strong>
                <br />
                Carrier Name: <strong>{this.props.MawbData[0].CarrierName}</strong>
                <br />
                Flight/Voyage: <strong>{this.props.MawbData[0].FlightVoyage}</strong>
                <br />
                Min ETA Discharge: <strong>{this.props.MawbData[0].MinETADischarge}</strong>
                <br />
                <br />
              </Panel>
            </Col>
            <Col md={12} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }}>
                Expected CTNS: <strong>{this.props.MawbData[0].ExpectedCartons}</strong>
                <br />
                Received CTNS: <strong>{this.props.MawbData[0].RecoveredPCS}</strong>
                <br />
                Tot Cartons: <strong>{this.props.MawbData[0].TotCartons}</strong>
                <br />
                Received Pallets: <strong>{this.props.MawbData[0].RecoveredPallets}</strong>
                <br />
                Weight (CHW): <strong>{this.props.MawbData[0].TotChargeable}</strong>
                <br />
              </Panel>
            </Col>
          </Row>





          <Row >

            <Col md={24} sm={24}>
              <Panel bordered style={{ marginBottom: 10 }} header={<Row>Evolve Data <Button style={{ float: 'right' }} onClick={(e) => { this.onClickOpenEvolveDataDrawer(e) }} >Edit</Button></Row>}>
                <Row >
                  <Col md={12} sm={24}>
                    No: <strong>{this.props.MawbData[0].No}</strong>
                    <br />
                    PMC: <strong>{this.props.MawbData[0].PMC}</strong>
                    <br />
                    Pickup By: <strong>{this.props.MawbData[0].PickupBy}</strong>
                    <br />
                    Trips: <strong>{this.props.MawbData[0].Trips}</strong>
                    <br />
                    Notes: <strong>{this.props.MawbData[0].Notes}</strong>
                    <br />
                    <br />
                  </Col>
                  <Col md={12} sm={24}>
                    Arrival Date: <strong>{this.props.MawbData[0].ArrivalTime}</strong>
                    <br />
                    LFD: <strong>{this.props.MawbData[0].LFD}</strong>
                    <br />
                    Airline Recovered: <strong>{this.props.MawbData[0].AirlineRecovered}</strong>
                    <br />
                    Arrival At Warehouse: <strong>{this.props.MawbData[0].ArrivalAtWarehouse}</strong>
                    <br />
                    Delivery Date: <strong>{this.props.MawbData[0].DeliveryTime}</strong>
                    <br />
                    NDC Date: <strong>{this.props.MawbData[0].DCRequiredDate}</strong>
                    <br />
                  </Col>
                </Row>
                {/* <Row >
                  <Col md={24} sm={24}>
                    Notes: <strong>{this.props.MawbData[0].Notes}</strong>
                    <br />
                  </Col>
                </Row> */}
              </Panel>
            </Col>
          </Row>





        </div>

    }
    else {
      detailsDiv = <div></div>;
    }



    let fileUploadsTable;
    if (this.props.isXdockMawbFileUploadLoading) {

      fileUploadsTable =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.XdockMawbFileUploadData) !== 'undefined' && this.props.XdockMawbFileUploadData != null && this.props.XdockMawbFileUploadData.length > 0) {
      var milestoneTableComponent = RTableGeneral(
        "FileUploadID",     //   keyColumn,
        this.props.XdockMawbFileUploadData,     //   tableData,
        XdockMawbFileUploadsDataElements,       //   DataElements,
        this.props.isXdockMawbFileUploadLoading,        //   loading,

        this.props.isMobile,        //   isMobile,
        this.props.history,     //   history,

        sortColumn,     //   sortColumn,
        sortType,       //   sortType,
        this.handleSortColumn,      //   handleSortColumn,

        null, // checkColEntry,
        null,       //   checkedKeys,
        null,       //   handleCheckAll,
        null,       //   handleCheck,

        true,      //   onClickEditMilestone,
        this.onClickDownloadFileUpload,       //   onClickSelect,
        'Download',       //   selectButtonText,
        null,       //   selectHighlightRowKey,

        true,       //   link1bool,
        'Delete',     //   link1text,
        this.onClickDeleteFileUpload,     //   link1onClick,

        false,       //   link2bool,
        null,       //   link2text,
        null  //   link2onClick
      )

      fileUploadsTable = <Panel
        bordered style={{ marginBottom: 10 }}
        header='File Uploads'
      >
        {milestoneTableComponent}
      </Panel>


    }
    else {
      fileUploadsTable = <div></div>
    }


    let hawbDiv;
    let rTableHawb;
    let receiveButton;
    let airlineReceiveButton;
    let receiveFormButton;
    if (this.props.isHawbLoading) {
      receiveButton = <div></div>
      airlineReceiveButton = <div></div>
      rTableHawb = <div></div>
      receiveFormButton = <div></div>
      hawbDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>

    }
    else if (this.props.isMawbLoading) {
      airlineReceiveButton = <div></div>
      receiveButton = <div></div>
      rTableHawb = <div></div>
      receiveFormButton = <div></div>
      hawbDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.HawbData) !== 'undefined' && this.props.HawbData != null) {
      airlineReceiveButton = <Button style={{ float: 'right' }} onClick={(e) => { this.onClickAirlineRecoveryButton(e) }} >Recovery Log</Button>

      if ([1, 2].includes(mawbCurrentStatus)) {
        receiveButton = <Button style={{ float: 'right' }} onClick={(e) => { this.onClickReceiveButton(e) }} >Receive</Button>
      }
      else {
        receiveButton = <div></div>
      }

      rTableHawb = RTable(
        this.props.HawbData,        // tableData,
        HawbDataElements,  // DataElements,
        sortColumn,                 // sortColumn,
        sortType,                   // sortType,
        this.props.isHawbLoading,         // loading,
        this.handleSortColumn,      // handleSortColumn,
        this.onClickDetails,        // onClickDetails,
        false,                      // checkColEntry,
        tableIdCol,                 // keyColumn,
        null,                       // checkedKeys,
        null,                       // handleCheckAll,
        null,                       // handleCheck,
        false,                      // boolSelectButton,
        null,     // onClickSelect,
        null,                       // selectButtonText,
        this.props.isMobile,        // isMobile,
        this.props.history,         // history,
        null,                        // highlightRowKey

        true,                        // editLink,
        'Clear Customs',                   // editLinkText,
        this.onClickClearCustoms                        // editLinkOnClick


      )





      let MawbExportLabel;
      try {
        MawbExportLabel = this.props.MawbData[0].Master
      }
      catch (e) {
        MawbExportLabel = ''
      }


      hawbDiv = <div>

        {(this.props.isMobile == true) ? <div></div> :
          <div>
            <Row>
              <ExcelFile element={<Button >Export Excel</Button>} filename={MawbExportLabel + '_' + currentDateTime}>
                <ExcelSheet data={this.props.HawbData} name={MawbExportLabel}>

                  {
                    HawbDataElements.map(
                      ({ dBKey, displayName }, idx) => {
                        return (
                          <ExcelColumn label={displayName} value={dBKey} />
                        );
                      }
                    )
                  }

                </ExcelSheet>
              </ExcelFile>
            </Row>
          </div>
        }



      </div>



      receiveFormButton = <div>
        {(this.props.isMobile == true) ? <div></div> :
          <div style={{ float: 'right' }}>
            <ExcelFile element={<Button style={{ float: 'right' }}>Breakdown Form</Button>} filename={MawbExportLabel + '_BreakdownForm_' + currentDateTime}>
              <ExcelSheet dataSet={this.formatDataSet(this.props.HawbData)} name="BreakdownForm">
              </ExcelSheet>
            </ExcelFile>
          </div>
        }
      </div>


    }
    else {
      receiveButton = <div></div>
      rTableHawb = <div></div>
      hawbDiv = <div></div>
      receiveFormButton = <div></div>
    }




    let currentReceiveMaster;
    try {
      if (typeof (this.state.receiveDataRowData.Master) !== 'undefined' && this.props.MawbData != null) {

        currentReceiveMaster = this.state.receiveDataRowData.Master
      }
      else {
        currentReceiveMaster = ''
      }
    }
    catch (e) {
      currentReceiveMaster = ''
    }

    let receiveDrawer;
    if (this.props.isHawbLoading) {
      receiveDrawer = <div align="center">
        <section className="panel">
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
    }
    else {
      receiveDrawer = <ReceiveMasterDrawer {...this.props} state={this.state} onChange={this.onChangeReceiveDrawer} confirm={this.onClickReceiveDrawerSave} close={this.closeReceiveDrawer} onClickAddMissing={this.onClickAddMissing} masterLabel={currentReceiveMaster} />
    }


    let currentEvolveDataMaster;
    try {
      if (typeof (this.props.MawbData[0].Master) !== 'undefined' && this.props.MawbData != null) {

        currentEvolveDataMaster = this.props.MawbData[0].Master
      }
      else {
        currentEvolveDataMaster = ''
      }
    }
    catch (e) {
      currentEvolveDataMaster = ''
    }







    let airlineRecoveryTimeline;
    if (this.props.isMawbRecoveryLoading) {
      airlineRecoveryTimeline =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.MawbRecoveryData[0]) !== 'undefined' && this.props.MawbRecoveryData != null) {
      airlineRecoveryTimeline =
        <div>

          <Timeline>
            {this.props.MawbRecoveryData.map(item => (
              <Timeline.Item>
                {item.EntryDT}  &nbsp;
                {item.EmailAddr}
                {item.LFD != null ? <l><br /> LFD:  {item.LFD} </l> : ' '}
                {item.PlannedPickupDT != null ? <l><br /> Planned Pickup Date:  {item.PlannedPickupDT} </l> : ' '}
                {item.DriverAssigned != null ? <l><br /> Driver Assigned:  {item.DriverAssigned} </l> : ' '}
                {item.PMC != null ? <l><br /> PMC:  {item.PMC} </l> : ' '}
                {item.Note != null ? <l><br /> Note:  {item.Note} </l> : ' '}
                {item.TripMade != null ? <l><br /> Trips Made:  {item.TripMade} </l> : ' '}
                {item.FreightRecovered != null ? <l><br /> Freight Recovered:  {item.FreightRecovered === true ? "True" : "False"} </l> : ' '}
                {item.PickupDT != null ? <l><br /> Pickup Date:  {item.PickupDT} </l> : ' '}
                {item.CartonsRecovered != null ? <l><br /> Cartons Recovered:  {item.CartonsRecovered} </l> : ' '}
                {item.PickedUpBy != null ? <l><br /> Picked Up By:  {item.PickedUpBy} </l> : ' '}
                {item.Carrier != null ? <l><br /> Carrier:  {item.Carrier} </l> : ' '}


              </Timeline.Item>
            ))}
          </Timeline>

        </div>
    }





    let mawbTimeline;
    if (this.props.isMawbStatusLoading) {
      mawbTimeline =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.MawbStatusData[0]) !== 'undefined' && this.props.MawbStatusData != null) {
      mawbTimeline =
        <div>

          <Timeline>
            {this.props.MawbStatusData.map(item => (
              <Timeline.Item>
                {item.MawbStatusDT}  &nbsp;
                {item.EmailAddr}
                {item.MawbStatusName != null ? <l><br /> Status:  {item.MawbStatusName} </l> : ' '}

                {item.CustomerName != null ? <l><br /> Customer:  {item.CustomerName} </l> : ' '}
                {item.WarehouseName != null ? <l><br /> Warehouse:  {item.WarehouseName} </l> : ' '}



              </Timeline.Item>
            ))}
          </Timeline>

        </div>
    }


    let airlineReceiveDrawer;
    if (this.props.isMawbLoading) {
      airlineReceiveDrawer = <div align="center">
        <section className="panel">
        </section>
        <Loader
          type="TailSpin"
          color="#3396E5"
          height="50"
          width="50"
        />
      </div>
    }
    else {
      airlineReceiveDrawer = <AirlineRecoveryDrawer {...this.props} state={this.state} onChange={this.onChangeAirlineRecoveryDrawer} confirm={this.onClickAirlineRecoveryDrawerSave} close={this.closeAirlineRecoveryDrawer} masterLabel={currentEvolveDataMaster} onChangePlannedPickupDT={this.onChangePlannedPickupDT} onChangeAirlineTripMade={this.onChangeAirlineTripMade} onChangeAirlineFreightRecovered={this.onChangeAirlineFreightRecovered} onChangePickupDT={this.onChangePickupDT} onChangeLFD={this.onChangeLFD} onChangeAirlineRecoveryPMC={this.onChangeAirlineRecoveryPMC} />
    }


    let loadDiv;
    let rTable;
    if (this.props.isLoadLoading) {
      rTable = <div></div>
      loadDiv =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.LoadData) !== 'undefined' && this.props.LoadData != null) {

      rTable = RTable(
        this.props.LoadData,        // tableData,
        LoadDataElements,  // DataElements,
        sortColumn,                 // sortColumn,
        sortType,                   // sortType,
        this.props.isLoadLoading,         // loading,
        this.handleSortColumn,      // handleSortColumn,
        this.onClickLoadDetails,        // onClickDetails,
        null,                      // checkColEntry,
        tableIdCol,                 // keyColumn,
        null,                       // checkedKeys,
        null,                       // handleCheckAll,
        null,                       // handleCheck,
        false,                      // boolSelectButton,
        null,     // onClickSelect,
        null,                       // selectButtonText,
        this.props.isMobile,        // isMobile,
        this.props.history,         // history,
        null                        // highlightRowKey
      )

      loadDiv = <div>

        {(this.props.isMobile == true) ? <div></div> :
          <div>
            <Row>
              <ExcelFile element={<Button >Export Excel</Button>} flename={'Load_' + currentDateTime}>
                <ExcelSheet data={this.props.LoadData} name="Load">
                  {
                    LoadDataElements.map(
                      ({ dBKey, displayName }, idx) => {
                        return (
                          <ExcelColumn label={displayName} value={dBKey} />
                        );
                      }
                    )
                  }
                </ExcelSheet>
              </ExcelFile>
            </Row>
          </div>
        }

      </div>
    }
    else {
      rTable = <div></div>
      loadDiv = <div></div>
    }




    let customsCityTimeline;
    if (this.props.isGetCustomsCityAPILoading) {

      customsCityTimeline =
        <div>
          <Panel header={<Row>Customs Events</Row>} bordered style={{ marginBottom: 10 }}>

            <div align="center">
              <section className="panel">
              </section>
              <Loader
                type="TailSpin"
                color="#3396E5"
                height="50"
                width="50"
              />
            </div>
          </Panel>
        </div>
    }
    else if (this.props.isCustomsCityMessagesLoading) {
      customsCityTimeline =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }

    else if (typeof (this.props.CustomsCityMessagesData[0]) !== 'undefined' && this.props.CustomsCityMessagesData != null) {

      customsCityTimeline =
        <div>
          <Panel header={<Row>Customs Events<Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetCustomsCity(e) }} >New Request</Button></Row>} bordered style={{ marginBottom: 10 }}>

            <Timeline>
              {this.props.CustomsCityMessagesData.map(item => (
                <Timeline.Item>
                  {item.CBPDateTime}  &nbsp;
                  {item.description}
                  {item.status != null ? <l><br /> Status:  {item.status} </l> : ' '}
                </Timeline.Item>
              ))}
            </Timeline>
          </Panel>
        </div>
    }
    else {
      customsCityTimeline =
        <div>
          <Panel header={<Row>Customs Events<Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetCustomsCity(e) }} >New Request</Button></Row>} bordered style={{ marginBottom: 10 }}>

          </Panel>
        </div>
    }



    let customsCityHistory;
    if (this.props.isXdockCustomsCityManifestHistoryLoading) {
      customsCityHistory =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="TailSpin"
            color="#3396E5"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.XdockCustomsCityManifestHistoryData[0]) !== 'undefined' && this.props.XdockCustomsCityManifestHistoryData != null) {

      var getCustomsCityAPI = <div></div>
      // Assuming this.props.XdockCustomsCityManifestHistoryData is an array of objects
      const manifestHistoryData = this.props.XdockCustomsCityManifestHistoryData;

      // Find the row with the greatest CreatedDT value
      const latestRow = manifestHistoryData.reduce((latest, current) => {
        return new Date(current.CreatedDT) > new Date(latest.CreatedDT) ? current : latest;
      }, manifestHistoryData[0]);

      // Check if the GetSuccess value of the latest row is false
      if (latestRow && latestRow.GetSuccess === false) {
        getCustomsCityAPI = <Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetOnlyCustomsCity(e) }} >Get Updates</Button>
        // <Panel header={<Row>Customs Events<Button style={{ float: 'right' }} onClick={(e) => { this.onClickGetCustomsCity(e) }} >Check Updates</Button></Row>} bordered style={{ marginBottom: 10 }}>
      } else {
        getCustomsCityAPI = <div></div>
      }



      customsCityHistory =
        <div>
          <Panel header={<Row>Customs Query History {getCustomsCityAPI} </Row>} bordered style={{ marginBottom: 10 }}>
            <Timeline>
              {this.props.XdockCustomsCityManifestHistoryData.map(item => (
                <Timeline.Item>
                  {item.CreatedDT}  &nbsp;

                  {item.GetSuccess != null ? <l><br /> Results Returned:  {formatBitToTrueFalse(item.GetSuccess)} </l> : ' '}
                  {item.GetMsg != null ? <l><br /> Response:  {item.GetMsg} </l> : ' '}

                </Timeline.Item>
              ))}
            </Timeline>
          </Panel>
        </div>
    }



    return (
      <div>
        <h3>
          <Row>{pageHeader}  {receiveFormButton} </Row>
        </h3>

        <PanelGroup>

          {detailsDiv}

          {/* {poProductDetailsDiv} */}

        </PanelGroup>


        <Panel bordered style={{ marginBottom: 10 }} header={<Row>Airline Recovery{airlineReceiveButton}</Row>}>
          {/* {airlineRecoverySummary} */}
          {airlineRecoveryTimeline}
        </Panel>
        {airlineReceiveDrawer}


        <Panel bordered style={{ marginBottom: 10 }} header={<Row>Status History{airlineReceiveButton}</Row>}>
          {/* {airlineRecoverySummary} */}
          {mawbTimeline}
        </Panel>


        <Panel header="House List" bordered style={{ marginBottom: 10 }}>
          {receiveButton}
          {hawbDiv}
          {rTableHawb}
        </Panel>


        {receiveDrawer}


        <MasterEvolveDataDrawer {...this.props} state={this.state} onChange={this.onChangeEvolveData} confirm={this.onClickConfirmEvolveData} close={this.closeEvolveDataDrawer} masterLabel={currentEvolveDataMaster} />

        {customsCityTimeline}


        {customsCityHistory}

        <Panel header="Load List" bordered style={{ marginBottom: 10 }}>
          {loadDiv}
          {rTable}
        </Panel>

        {/* {mawbStatusTimeline} */}

        <AddNewHawbModal {...this.props} state={this.state} addNewModalShown={this.state.addNewModalShown} onChangeExisting={this.onChangeAddNewModal} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseAddNewModal} handleSave={this.handleSaveAddNewModal} />

        {fileUploadsTable}
        {fileUploadDiv}

      </div>
    );

  }
}

export default withRouter(MasterDetails);

